import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'Home',
    meta: {
        label: '首页',
        title: '企业差旅_团队机票_公务机票_打折机票',
        pos: 'header'
    },
    component: () => import('@/views/Home.vue')
},
{
    path: '/qycl',
    name: 'QYCL',
    meta: {
        label: '企业差旅',
        title: '企业差旅',
        pos: 'header'

    },
    component: () => import('@/views/Qycl.vue')
},
{
    path: '/tdjp',
    name: 'TDJP',
    meta: {
        label: '团队机票',
        title: '团队机票',
        pos: 'header'

    },
    component: () => import('@/views/Tdjp.vue')
},
{
    path: '/gwjp',
    name: 'GWJP',
    meta: {
        label: '公务机票',
        title: '公务机票',
        pos: 'header'

    },
    component: () => import('@/views/Gwjp.vue')
},
{
    path: '/gjjp',
    name: 'GJJP',
    meta: {
        label: '国际机票',
        title: '国际机票',
        pos: 'header'

    },
    component: () => import('@/views/Gjjp.vue')
},
{
    path: '/fwbz',
    name: 'FWBZ',
    meta: {
        label: '服务保障',
        title: '服务保障',
        pos: 'header'

    },
    component: () => import('@/views/Fwbz.vue')
},
{
    path: '/zxdt',
    name: 'ZXDD',
    meta: {
        label: '最新动态',
        title: '最新动态',
        pos: 'header'

    },
    component: () => import('@/views/Zxdt.vue')
},
{
    path: '/gywm',
    name: 'GYWM',
    meta: {
        label: '关于我们',
        title: '关于我们',
        pos: 'header'

    },
    component: () => import('@/views/Gywm.vue')
},
{
    path: '/',
    name: 'WZSY',
    meta: {
        label: '网站首页',
        title: '网站首页',
        pos: 'footer'

    },
    component: () => import('@/App.vue')
},
{
    path: '/',
    name: 'JJYA',
    meta: {
        label: '解决方案',
        title: '解决方案',
        pos: 'footer'

    },
    component: () => import('@/views/Home.vue')
},
{
    path: '/qycl',
    name: 'HJLY',
    meta: {
        label: '会奖·旅游',
        title: '会奖·旅游',
        pos: 'footer'

    },
    component: () => import('@/views/Qycl.vue')
},
{
    path: '/fwbz',
    name: 'Fubz',
    meta: {
        label: '服务保障',
        title: '服务保障',
        pos: 'footer'

    },
    component: () => import('@/App.vue')
},
{
    path: '/zxdt',
    name: 'XWZX',
    meta: {
        label: '新闻中心',
        title: '新闻中心',
        pos: 'footer'

    },
    component: () => import('@/views/Zxdt.vue')
},
{
    path: '/gywm',
    name: 'About',
    meta: {
        label: '关于我们',
        title: '关于我们',
        pos: 'footer'

    },
    component: () => import('@/App.vue')
}]

const router = new VueRouter({
    routes,
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0};
    },
})

// 设置页面标题
router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}-五洲商旅`
    next()
})

export default router
