const app = {
    state: {
        navbar: {
            currentPath: '/'
        },
        footer: {
            showCooper: true
        }
    },
    mutations: {
        CHANGE_CURRENT_PATH: (state, currentPath) => {
            state.navbar.currentPath = currentPath
        },
        SET_SHOW_COOPER: (state, flag) => {
            state.footer.showCooper = flag
        }
    },
    actions: {
        changeCurrentPath({
            commit
        },
            currentPath) {
            commit("CHANGE_CURRENT_PATH", currentPath)
        },
        setShowCooper({commit}, flag) {
            commit('SET_SHOW_COOPER', flag)
        }
    }
}

export default app
