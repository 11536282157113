<template>
  <div>
    <div :class="{ is_hide: !footer.showCooper }">
      <div class="serve_title">合作单位</div>
      <div class="serve_desc">
        未来，五洲商旅将继续秉承着客户至上、服务为本的原则，继续为企业出行赋能，为企业降本提效、集中精力于主营业务即刻加入五洲商旅，与600000+企业一起体验全新的出行方式。
      </div>

      <div class="cooper_name">
        <table>
          <tr>
            <td>中央军委机关事务管理总局</td>
            <td>中国人民解放军海军</td>
            <td>中国人民解放军工程建设指挥部</td>
          </tr>
          <tr>
            <td>中国公路工程集团有限公司</td>
            <td>中国二十冶集团有限公司</td>
            <td>中国国际贸易促进委员会</td>
          </tr>
          <tr>
            <td>中国国际战略研究基金会</td>
            <td>中国食品土畜进出口商会</td>
            <td>中国中医科学院</td>
          </tr>
        </table>
      </div>

      <div class="cooper_icon">
        <div v-for="(item, index) in cooperIcons1" :key="index">
          <img :src="item" />
        </div>
      </div>
      <div class="cooper_icon">
        <div v-for="(item, index) in cooperIcons2" :key="index">
          <img :src="item" />
        </div>
      </div>
      <div class="cooper_icon">
        <div v-for="(item, index) in cooperIcons3" :key="index">
          <img :src="item" />
        </div>
      </div>
    </div>

    <div class="leave_msg_container">
      <div class="leave_msg_title">立即咨询</div>
      <div class="leave_msg_desc">免费获得企业专属差旅管理解决方案</div>
      <el-form :model="form" label-width="100px">
        <div class="leave_msg_form">
          <el-form-item label="您的姓名：" class="leave_msg_label">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="您的电话：" class="leave_msg_label">
            <el-input v-model="form.tel"></el-input>
          </el-form-item>
          <el-form-item label="咨询内容：" class="leave_msg_label">
            <el-input
              type="textarea"
              v-model="form.desc"
              rows="5"
              resize="none"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码：" class="leave_msg_label">
            <el-input v-model="form.tel" style="width: 50%"></el-input>
            <img :src="codeUrl" alt="验证码" @click="changeCode" />
          </el-form-item>
        </div>
        <div class="leave_msg_btn_container">
          <el-button class="leave_msg_btn">提交</el-button>
        </div>
      </el-form>
    </div>

    <div class="footer_bg">
      <div class="footer_left">
        <div class="nav_list">
          <router-link
            v-for="item in footerRoutes"
            :to="item.path"
            :key="item.name"
            @click.native="setCurrentPath(item.path)"
            >{{ item.meta.label }}</router-link
          >
        </div>
        <div class="footer_mark">
          <img src="@/assets/iata.png" alt="" />
          <img src="@/assets/cata.png" alt="" />
          <img src="@/assets/tis.png" alt="" />
        </div>
      </div>
      <div class="footer_right">
        <div>咨询热线：010-65538808</div>
        <div>商务合作：010-65538808</div>
        <div>意见建议：wuzhouair@163.com</div>
        <div>公司地址：北京市海淀区永定路长银大厦7C15</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Footer",
  props: {
    routes: {
      type: Array,
    },
  },
  data() {
    return {
      cooperIcons1: [
        require("../assets/cooper/zgtj.png"),
        require("../assets/cooper/kxsp.png"),
        require("../assets/cooper/zgjy.png"),
        require("../assets/cooper/zggl.png"),
      ],
      cooperIcons2: [
        require("../assets/cooper/zggm.png"),
        require("../assets/cooper/zg20.png"),
        require("../assets/cooper/cits.png"),
        require("../assets/cooper/cct.png"),
      ],
      cooperIcons3: [
        require("../assets/cooper/hygl.png"),
        require("../assets/cooper/zlty.png"),
        require("../assets/cooper/csit.png"),
        require("../assets/cooper/btg.png"),
      ],
      form: {},
      codeUrl: "",
    };
  },
  created() {
    this.changeCode();
  },
  computed: {
    ...mapGetters(["footer"]),
    footerRoutes: function () {
      return this.routes.filter(function (route) {
        return route.meta.pos == "footer";
      });
    },
  },
  methods: {
    changeCode() {
      const timestamp = new Date().getUTCMilliseconds();
      const url = `http://www.wuzhouair.com/Common/GenerateLeavewordCaptcha?timestamp=${timestamp}`;
      this.codeUrl = url;
    },
    setCurrentPath(currentPath) {
      this.$store.dispatch("changeCurrentPath", currentPath);
    },
  },
};
</script>

<style lang="scss" scoped>
.serve_title {
  font-size: 28px;
  color: $black_color;
  position: relative;
  text-align: center;
  padding: 80px 0 30px 0;
}

.serve_desc {
  color: $gray_color;
  font-size: 16px;
  text-align: center;
  padding: 0 20% 60px 20%;
}

.cooper_name {
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 18px;

  table {
    width: 90%;
    border-collapse: collapse;

    td {
      background-color: $white_color;
      border: 1px solid #f0f0f0;
      padding: 20px 0;
    }
  }
}

.cooper_icon {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 40px;

  div {
    width: 294px;
    height: 130px;
    text-align: center;
    background-color: $white_color;
    box-shadow: 0px 6px 17px 0px rgba(0, 141, 240, 0.08),
      0px 20px 30px 0px #f7f7f7;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  div:nth-child(n + 2) {
    margin-left: 40px;
  }

  img {
    max-width: 60%;
    max-height: 60%;
  }
}

.cooper_icon:last-of-type {
  margin-bottom: 60px;
}

.leave_msg_container {
  background: url("@/assets/leavemsg.png") no-repeat;
  background-size: 100% 100%;
  color: $white_color;
  display: flex;
  flex-direction: column;
  align-items: center;

  .leave_msg_title {
    font-size: 28px;
    text-align: center;
    padding: 40px 0 28px 0;
  }

  .leave_msg_desc {
    font-size: 16px;
    text-align: center;
    padding-bottom: 40px;
  }

  .leave_msg_form {
    width: 655px;
    background: rgba(255, 255, 255, 0.24);
    padding: 40px 300px 20px 300px;

    img {
      height: 40px;
      margin-left: 10px;
      vertical-align: middle;
    }
  }

  .leave_msg_btn_container {
    text-align: center;
    margin-bottom: 40px;
  }
}

.footer_bg {
  background-color: #2c2c2c;
  display: flex;
  justify-content: space-around;
  font-size: 14px;
  padding: 50px 0;
}

.footer_right {
  color: $white_color;
  margin-left: 450px;

  div:nth-child(n + 2) {
    margin-top: 19px;
  }
}

.footer_mark {
  margin-top: 40px;

  img {
    margin-left: 40px;
    max-width: 120px;
    max-height: 70px;
  }
}

.nav_list a {
  color: $white_color !important;
  margin-left: 40px;
}

.nav_list a:visited {
  color: #000000;
}

.nav_list a:hover {
  color: $blue_color !important;
}
</style>

<style lang="scss">
// 重写　Element-UI 样式
.leave_msg_btn {
  width: 150px;
  height: 50px;
  background-color: #eb690c;
  font-size: 16px;
  color: $white_color;
  border: none;
  margin-top: 40px;
}

.leave_msg_label .el-form-item__label {
  font-size: 16px;
  color: $white_color;
}

.leave_msg_label .el-input__inner,
.leave_msg_label .el-textarea__inner {
  font-size: 16px;
  background: rgba(255, 255, 255, 0.04);
  border: 1px solid #89ccfa;
  border-radius: 4px;
}
</style>
